.footer {
    width: 100%;
    background-color: #181E26;
    color: #E6D4D4;
    display: flex;
    height: 75px;
    box-shadow: 0 0 20px 4px #181E26;
    justify-content: right;
}

.footer .layoutHorizontal {
    flex-direction: row !important;
}

.footer p {
    text-align: center;
    font-family: 'Montserrat-Light';
    margin-right: 50px;
    font-size: 1rem;
}

.site-map {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.site-map-container p {
    margin: 5px 10px;
    text-align: left;
}

.footer .footer-link {
    margin-right: 50px;
}

.footer img {
    height: 40px;
    margin: 10px;
}