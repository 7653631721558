.questPage {
    margin: 0;
    color: #E6D4D4;
    padding: 20vh 0;
    width: 100vw;
}

.questPage h1 {
    font-size: 60px;
    text-align: center;
    font-family: 'Montserrat-Bold';
    text-shadow: 4px 4px 4px #181E26;
    margin: 40px 0;
    width: 100%;
  }

.questPage h2 {
    font-size: 40px;
    text-align: center;
    font-family: 'Montserrat-Bold';
    text-shadow: 4px 4px 4px #181E26;
}

.questPage h3 {
    font-size: 25px;
    text-align: center;
    font-family: 'Montserrat-Bold';
    text-shadow: 4px 4px 4px #181E26;
}

.questPage p {
    font-size: 25px;
    margin: 0 8vw;
    text-align: center;
}

.questPage .questGrid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 50px 10vw;
    align-items: center;
    justify-items: center;
}

.questPage img {
    width: 20vw;
    height: auto;
    border-radius: 30px;
    box-shadow: 0px 0px 5px 5px #181E26;
}

.questPage table {
    margin: 10px auto;
}

.questPage table, td, th {
    border: 1px solid #E6D4D4;
    border-collapse: collapse;
    width: max-content;
    height: max-content;
    padding: 20px 15px;
    text-align: center;
}

.questPage .caption {
    padding: 30px 10px;
    font-size: 20px;
    font-family: 'Montserrat-Bold';
}

.questPage input[type=email] {
    font-size: 18px;
    background-color: #E6D4D4;
    color:#181E26;
    margin: 15px;
    display: inline-block;
    border-radius: 10px;
    height: 30px;
}

.questPage label {
    font-size: 20px;
    margin: 15px;
}

.questPage select {
    background-color: #E6D4D4;
    color:#181E26;
    font-size: 20px;
    margin: 15px;
    border-radius: 10px;
}

.questPage .inputContainer {
    padding-top: 10px;
    text-align: center;
}


.questPage button[type=submit] {
    margin: 30px auto;
    padding: 20px;
    font-size: 20px;
    font-family: 'Montserrat-Bold';
    background-color: #181E26;
    color: #E6D4D4;
    display: block;
    border-radius: 10px;
    border: none;
}

.questPage .error {
    display: block;
    font-size: 20px;
    text-align: center;
    color: red;
}

.questPage #emailThanks {
    display: block;
    font-size: 20px;
    text-align: center;
    margin: 30px auto;
    width: 80%;
}

.questPage .story {
    grid-column: 1 / 3;
    padding-top: 100px;
}

.questPage .story p {
    text-align: left;
    padding-bottom: 20px;
}

.questPage .story .buttonContainer{
    justify-items: center;
    margin: 10px auto;
    text-align: center;
}

.questPage .story button {
    display: inline-block;
    margin: 20px;
}

.questPage button {
    padding: 20px;
    font-size: 20px;
    font-family: 'Montserrat-Bold';
    background-color: #181E26;
    color: #E6D4D4;
    border-radius: 10px;
    border: none;
}

.questPage button:disabled {
    background-color: rgb(146, 141, 141);
}

.questPage .decStats {
    padding: 5px 10px;
    margin: 10px;
}

.questPage .incStats {
    padding: 5px 10px;
    margin: 10px;
}

.questPage .submitStats {
    margin: 30px auto;
}

.questPage table.currentStandings {
    max-width: 600px;
    min-width: 200px;
    width: 90%;
}

.questPage table.currentStandings thead {
    font-family: 'Montserrat-Bold';
    font-size: 20px;
}

