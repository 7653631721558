@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: url('../../public/Montserrat-ExtraLight.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../../public/MontserratBold-p781R.otf')
}

@font-face {
  font-family: 'Montserrat-Light';
  src: url('../../public/Montserrat-Light.ttf')
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../../public/Montserrat-Regular.ttf')
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  background: linear-gradient(170deg, #181E26 25%, #41546C 100%); 
  /* background: linear-gradient(90deg, #41546C 25%, #181E26); */
  background-attachment: fixed;
  background-repeat: no-repeat;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

/* include border and padding in element width and height */
* {
  box-sizing: border-box;
}

#root {
  height: 100vh;
  width: 100vw;
  font-family: 'Montserrat-ExtraLight';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.home-page::-webkit-scrollbar {
  display: none;
}

/* Typical Font Styling */
h1 {
  font-size: 5rem;
  margin: 0;
}

h2 {
  font-size: 3rem;
  margin: 0;
}

h3 {
  font-size: 1.75rem;
  margin: 0;
}

h4 {
  font-size: 1.75rem;
}

p {
  font-size: 1.25rem;
}

.bold {
  font-family: 'Montserrat-Bold';
}

.error {
  display: block;
  font-size: 20px;
  text-align: center;
  margin: 2px;
  color: red;
}

.content-width {
  width: 85%;
  max-width: 1700px;
  margin: 0 auto;
}

.hero {
  width: 100%;
  height: calc(100vh - 100px);
  max-height: 850px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  background-color: #181E26;
  box-shadow: 0 0 100px 100px #181E26;
  z-index: 0;
  margin-bottom: 150px;
}

.hero-content {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 30px;
  margin: 0 auto;
}

.hero-content .text-box {
  width: 55%;
  text-shadow: 2px 2px 4px  #181E26;
}

.button {
  background-color: transparent;
  border: 3px solid #272e38;
  margin: 20px;
  padding: 15px;
  width: max-content;
  border-radius: 25px;
  display: inline;
  font-family: 'Montserrat-Bold';
  color: #272e38;
  font-size: 1.25rem;
}

.light .button {
  border: 3px solid #E6D4D4;
  color: #E6D4D4;
}

.feature-banner {
  max-height: 80vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: max-content;
  padding: 60px;
  box-shadow: 0px 0px 20px 5px #181E26;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
}

.light {
  color: #E6D4D4;
}

.dark { 
  color:#181E26;
}

.feature-banner-contents {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 50%;
  min-height: 500px;
}

.feature-banner h2 {
  font-family: 'Montserrat-Bold';
}

.feature-banner p {
  padding: 10px;
  font-family: 'Montserrat-Regular';
  text-shadow: 1px 1px 2px #63686d;
  backdrop-filter: blur(5px);
  border-radius: 25px;
  box-shadow: 0px 6px 10px 0px #181E26;
}

.dark p{
  background-color: rgba(255, 255, 255, 0.25);
}

.feature-banner img {
  height: 85%;
  width: 45%;
  object-fit: contain;
}

#tt-feature p {
  max-width: 80%;
}

.card {
  flex-grow: 1;
  width: 225px;
  margin: 0 25px;
}

.card img {
  max-width: 75%;
  width: auto;
  height: auto;
  object-fit: contain;
  box-shadow: 0px 0px 20px 5px #181E26;
  margin-bottom: 25px;
}

.card .png {
  box-shadow: none;
}

.content-block {
  margin: 60px 30px;
}

.centerText {
  text-align: center;
}

.layoutHorizontal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}