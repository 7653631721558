.services-page {
    font-family: 'Montserrat-Light';
    font-weight: 500;
    color:#E6D4D4;
    /* max-width: 1700px;
    margin: 0 auto; */
}

.services-page .feature-banner {
    text-align: right;
}

.services-page .hero {
    height: calc(100vh - 350px);
    padding-top: 80px;
    margin-bottom: 0px;
}

.services-page #large-logo {
    width: 40%;
    padding-bottom: 100px;
}

.services-page .content-block {
    height: 300px;
}

#services-cards {
    display: flex;
    flex-direction: row;
}

.serviceCard {
    background-color: #181E26;
    color: #E6D4D4;
    padding: 20px;
    height: 190px;
    margin: 25px 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    justify-content: start;
    box-shadow: 0 0 20px 5px #181E26;
    /* width: 15%; */
    transition: all 0.25s ease-in;
}

.serviceCard img {
    width: 80%;
    height: 125px;
    object-fit: contain;
}

.serviceCard p {
    /* display: none; */
    opacity: 0;
    margin-top: -20px;
}

.serviceCard:hover {
    height: 250px;
    transition: all 0.25s ease-in;
    animation-name: cardBounce;
    animation-timing-function: linear;
    animation-duration: 0.75s;
}

.serviceCard:hover p {
    opacity: 100;
    margin-top: 20px;
    transition: all 0.25s ease-in;
}

.contactContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10%;
    width: 90%;
    padding-top: 100px;
    margin: auto auto 50px auto;
    align-items: center;
    color: #E6D4D4;
}

form {
    margin: 0 auto 0 -10px;
    width: max-content;

}

input {
    font-size: 1.5rem;
    margin: 10px;
    height: 2rem;
    padding: 0 10px;
    width: 500px;
    background-color: #181E26;
    color: #E6D4D4;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 4px 5px #181e267d;
    font-family: 'Montserrat-Regular';
}

textarea {
    font-size: 1.5rem;
    margin: 10px;
    width: 500px;
    background-color: #181E26;
    border: none;
    border-radius: 5px;
    box-shadow: 4px 4px 5px #181e267d;
    padding: 10px;
    font-family: 'Montserrat-Regular';
    color: #E6D4D4;
}

.contactContainer .button {
    margin: 15px auto;
}

.contactContainer img {
    width: 100%;
    height: 50vh;
    object-fit: contain;
}

.reviews { 
    color: #E6D4D4;
    text-align: center;
    padding: 50px;
}

.reviewGallery {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 1300px;
    margin: 25px auto;
    max-width: fit-content;
}

.reviewCard {
    background: linear-gradient(-170deg,#ffffff00 0%, #41546C 100%);
    color: #E6D4D4;
    box-shadow: 0 0 20px 5px #181E26;
    width: 28%;
    margin: 20px;
    padding: 15px;
    transition: all 0.15s;
}

.reviewCard:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px 5px #FFFFFF;
}

.reviewCard img {
    height: 100px;
    width: 100px;
    box-shadow: 0px 2px 10px 0px #181E26;
    border-radius: 50px;
}

/* @keyframes cardBounce {
    0% { transform: scale(100%) }
    25% { transform: scale(105%) }
    50% { transform: scale(95%) }
    75% { transform: scale(100%) }
} */

@keyframes cardBounce {
    0% { transform: rotate(0deg) }
    15% { transform: rotate(5deg) }
    30% { transform: rotate(0deg) }
    45% { transform: rotate(-5deg) }
    60% { transform: rotate(0deg) }
    75% { transform: rotate(5deg) }
}