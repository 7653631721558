@media only screen and (max-width: 1400px) {  
    .team-page img{
        height: 350px;
    }

}

@media only screen and (max-width: 1000px) { 
    #teamMemberInfo {
        text-align: left;
    }

    .teamEven {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: 'pic' 
                            'info';
    }

    .teamOdd {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: 'pic' 
                            'info';
    }

    .team-page {
        /* grid-template-rows: auto 1fr 1fr 1fr; */
        padding-top: 150px;
    }

    .team-page .headshot{
        height: 250px;
      }
}
