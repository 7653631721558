@media only screen and (max-width: 1200px) {
    .site-map-container {
        display: none;
    }

    .footer .content-width {
        justify-content: center !important;
    }
}

@media only screen and (max-width: 600px) {
    #grimbar-footer-text {
        display: none;
    }
}
