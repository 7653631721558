.businessCard {
	color:#E6D4D4;
	background-color: #181E26;
	padding: calc(25px);
	height: 100%;
	width: 100%;
  margin: 0px;
  font-family: 'Montserrat-ExtraLight';
	text-align: center;
}

.businessCard h1 {
	color:#E6D4D4;
	font-family: 'Montserrat-Bold';
}

.businessCard h3 {
	margin: 40px;
}

.businessCard #teamEmail {
	margin: 20px 0px 30px;
}

a {
    text-decoration: none;
    color:#E6D4D4;
}

a:visited {
    color:#E6D4D4;
}

img {
	height: 50px;
	margin: 10px 10px 0px 10px;
}

.businessCard .headshot {
	height: 200px;
	border-radius: 20px;
	box-shadow: #000000 0px 5px 10px;
}