@media only screen and (max-width: 768px) {

    #nav-bar-container {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 80px 400px;
        grid-template-areas: "logos return"
                            "mobilenavbar mobilenavbar";
        /* margin-right: 0px; */
    }

    .icon {
        display: inline-block;
    }

    #desktopNav {
        display: none;
    }

    #mobileNav {
        display: none;
        grid-area: mobilenavbar;
        background-color: #181e26e0;
        text-align: right;
        padding: 0px 15px;
        margin: 0;
        align-self: flex-start;
    }

    #mobileNav .navbutton {
        display: block;
        background-color: transparent;
        width: max-content;
        margin-right: 0px;
        margin-left: auto;
        padding-left: 10px;
        font-size: 1.25rem;
        padding: 5px;
    }

    #return-up {
        right: 0px;
    }

    .icon {
        float: left;
    }

    #return-up {
        right: 0px;
        bottom: 100px;
    }

    /* #grimbar-logo {
        height: 45px;
    }

    #grimbar-name {
        height: 25px;
    } */

    

}