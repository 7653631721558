.home-page {
  font-family: 'Montserrat-Light';
  font-weight: 500;
  color:#E6D4D4;
  text-align: left;
}

.typing-container {
  display: flex;
  justify-content: start;
  align-items: center;
  height: 5rem;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 5rem;
  background-color: #E6D4D4;
  margin-left: 8px;
  animation: blink 1s linear infinite;
}

@keyframes blink {
  0% {opacity: 1;}
  40% {opacity: 1;}
  60% {opacity: 0;}
  100% {opacity: 0;}
}

.home-page .text-box h3 {
  text-shadow: none;
}

.text-link {
  cursor: pointer;
  color: cadetblue;
}

#large-logo {
  height: auto;
  width: 45%;
  object-fit: cover;
  margin: 50px auto 0px; /* Shifting image down a bit */
  mask-image: linear-gradient(to bottom right, transparent 30%, white);
  mask-mode: alpha;
  mask-position: center;
  mask-repeat: no-repeat;
  position: absolute;
  right: calc(7.5% + 30px);
}

