@media only screen and (max-width: 1400px) {
    .projects-page {
        padding-top: 100px;
    }
}

@media only screen and (max-width: 1000px) {  
    /* .image {
        margin: 5px;
        height: 14vw;
        width: 23.33vw;
    }
    
    .imageFocus {
        padding: 10px;
        height: 17vw;
        width: 22.33vw;
    }

    .imageUnfocus {
        margin: 10px;
        height: 13vw;
        width: 21.66vw;
        filter: grayscale(0%) blur(0px);
    }

    .projects-page .image:hover {
        height: 16vw;
        width: 26.66vw;
      }
      
    .projects-page .imageUnfocus:hover {
        height: 16vw;
        width: 26.66vw;
    } */

    .projects-page h1 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 1000px) {
    .projects-page {
        padding-top: 50px;
    }

    .image {
        margin: 30px auto;
        height: 30vw;
        width: 50vw;
        max-width: none;
    }
    
    .imageFocus {
        padding: 10px;
        height: 35vw;
        width: 58.33vw;
        margin: 30px auto;
        max-width: none;
    }

    .imageUnfocus {
        margin: 10px;
        height: 25vw;
        width: 41.66vw;
        margin: 30px auto;
        max-width: none;
    }

    .projects-page .image:hover {
        margin: 30px auto;
        height: 30vw;
        width: 50vw;
      }
      
    .projects-page .imageUnfocus:hover {
        margin: 10px;
        height: 25vw;
        width: 41.66vw;
        margin: 30px auto;
    }

    .projects-page h2 {
        font-size: 1.5rem !important;
      }
      
    .projects-page p {
        font-size: 14px;
    }

    .projectHeader {
        flex-direction: column;
    }

    .projectInfo img {
      height: 30px;
      width: 30px;
    }
}
