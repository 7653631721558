#gdc-calendar-tool {
  background-image: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: calc(100vh - 75px)
}

.home-page a {
  color:cadetblue;
}

.home-page a:hover {
  text-decoration: underline;
}

.circle {
  height: 160px;
  width: 160px;
  border-radius: 15%;
  background-color: white;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle img {
  margin: 0px;
  height: 128px;
  width: 128px;
}

#privacy {
  background-color: white;
  padding: 64px;
}

#privacy a {
  color:blue;
}