.projects-page {
  padding-top: 75px;
  padding-bottom: 25px;
  justify-content: center;
  text-align: center;
  margin: 0px auto;
  color: #E6D4D4;
  max-width: 1700px;
  /* margin: 0 auto; */
}

.projectSection {
  width: 100%;
  background: linear-gradient(-170deg,#ffffff00 0%, #41546C 100%);
  padding-bottom: 50px;
  box-shadow: 0 0 20px 5px #181E26;
  margin: 0 auto 50px auto;
}

.projectLibrary {
  width: 100%;
  display: flex;
  /* margin: 0 auto; */
  align-items: center;
  justify-content: space-around;
  padding: 10px 20px;
  column-count: 3;
}

.projects-page a {
  text-decoration: underline;
}

.projects-page h1 {
  text-align: center;
  font-family: 'Montserrat-Bold';
  font-size: 3.5rem;
  text-shadow: 4px 4px 4px  #181E26;
  padding-top: 50px;
  margin-top: 50px;
}

.projects-page h2 {
  text-align: left;
  font-family: 'Montserrat-Bold';
  text-shadow: 4px 4px 4px  #181E26;
  font-size: 2.5rem;
}

.projectInfo {
  grid-row: 2;
  grid-column: 1 / 4;
  text-align: left;
  width: 85%;
  text-align: left;
  margin: 10px auto 0px auto;
  padding: 20px;
  background-color: #181E26;
  box-shadow: 0 0 20px 5px #181E26;
}

.projectInfo h4 {
  margin: 0px;
  margin-top: 10px;
}

.projectInfo p {
  margin-bottom: 0px;
}

.projectInfo img {
  height: 40px;
  width: 40px;
}

.projectHeader {
  display: flex;
  justify-content: space-between;
  height: min-content;
}

.projects-page .image:hover {
  transform: scale(1.1);
}

.projects-page .imageUnfocus:hover {
  transform: scale(1.1);
  filter: grayscale(0%) blur(0px);
  box-shadow: 0 0 20px 5px #181E26;
}

.image {
  height: 250px;
  width: 300px;
  max-width: 30%;
  transition: all 0.2s;
  margin: 20px;
  object-fit: contain;
  box-shadow: 0 0 20px 5px #181E26;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #181E26;
  flex-grow: 1;
  flex-shrink: 1;
}

/* .glare {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(40deg, #0000 30%, #fff2 50%, #0000 70%);
} */

.imageFocus {
  height: 275px;
  width: 330px;
  margin: 20px;
  max-width: 30%;
  justify-self: center;
  transition: all 0.2s;
  box-shadow: 0 0 20px 5px #FFFFFF;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.imageUnfocus {
  height: 225px;
  width: 275px;
  margin: 20px;
  max-width: 30%;
  opacity: 50;
  transition: all 0.2s;
  filter: grayscale(50%) blur(2px);
  /* box-shadow: 0 0 20px 5px #181E26; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: 0px solid #181E26;
  flex-grow: 1;
  flex-shrink: 1;
}