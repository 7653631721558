@media only screen and (max-width: 1024px) {
    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2rem;
    }

    h3, h4 {
        font-size: 1.25rem;
    }

    p {
        font-size: 1rem !important;
    }
}

@media only screen and (max-width: 768px) {
    .hero-content .text-box {
        margin-left: 10%;
    }

    .feature-banner {
        padding: 30px;
        flex-direction: column-reverse;
        max-height: none;
    }

    .feature-banner-contents {
        display: flex;
        width: 100%;
        min-height: 0px;
        justify-content: center;
    }

    .feature-banner img {
        width: 100%;
        margin-bottom: 50px;
    }

    #tt-feature p {
        width: 100%;
        max-width: 100%;
    }

    .layoutHorizontal {
        flex-direction: column;
    }

    .content-width {
        width: 90% !important;
    }
}

@media only screen and (max-width: 700px) {
    .hero-content {
        justify-content: center;
        padding: 0px 10px;
    }

    .hero-content .text-box {
        margin: 0px;
        width: 100%;
    }
}