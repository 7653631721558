@media only screen and (max-width: 1200px) {  
    .services-page .content-block {
        height: max-content;
    }

    .serviceCard {
        width: 190px;
    }

    .contactContainer {
        display: block;
        text-align: center;
    }

    .contactContainer img {
        display: none;
    }

    form {
        text-align: center;
        margin: auto;
    }

    input {
        width: 500px;
        margin: 10px 0;
    }

    textarea {
        width: 100%;
        margin: 10px 0;
    }

    .contactContainer .button {
        margin: 15px 10px;
    }

    .reviewCard {
        margin: 25px 0;
        width: 100%;
    }

    .reviewGallery {
        max-height: none;
    }
}

@media only screen and (max-width: 600px) { 
    input {
        width: 100%;
    }
}