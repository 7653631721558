#nav-bar {
    width: 100vw;
    position: fixed;
    z-index: 10;
    transition: all 0.25s;
}

#nav-bar-container {
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 2fr 5fr 75px;
    grid-template-areas: "logos navbar return";
    display: grid;
    row-gap: 0px;
    height: 80px;
    transition: all 0.25s;
    width: 85%;
    max-width: 1700px;
    margin: 0 auto;
}

.nav-bar-translucent-background {
    background-color: rgba(24, 30, 38, 0.92) !important;
    box-shadow: 0 0 20px 5px rgb(24, 30, 38);
    backdrop-filter: blur(5px);
}

#logo-container {
    margin: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: left;
    grid-area: logos;
}

#grimbar-logo {
    height: 60px;
    margin: 4px 0px;
    transition: all 0.25s;
}

#grimbar-name {
    height: 30px;
    opacity: 100;
    transition: all 0.25s;
    margin: 10px;
}

#icons {
    grid-area: return;
    display: inline-flex;
    align-items: center;
    justify-content: right;
}

#return-up {
    height: 30px;
    color: #E6D4D4;
    opacity: 0;
    transition: all 0.125s;
    margin: 15px;
    position: fixed;
    top: calc(100vh - 70px);
    right: 20px;
}

.return-up-visible {
    opacity: 100 !important;
}

nav {
    grid-area: navbar;
    justify-self: end;
}

.navbutton {
    margin: 0px 0px -5px 40px;
    border: none;
    font-family: 'Montserrat-ExtraLight';
    background-color: inherit;
    color: #E6D4D4;
    font-size: 18px;
    display: inline-block;
    padding: 20px 0px;
    text-decoration: none;
}

.navbutton:hover {
    color: #969DA7;
    cursor: pointer;
}

.navbutton::after {
    content: '';
    margin-top: 10px;
    display: block;
    width: 0;
    height: 2px;
    background-color: #969DA7;
    transition: width .3s;
}

.navbutton:hover::after {
    width: 100%;
    transition: width .3s;
}

.icon {
    display: none;
    height: 35px;
    margin: 0 20px;
    grid-area: return;
    justify-self: end;
}

#desktopNav {
    margin-right: -55px;
}

#mobileNav {
    display: none;
    background: linear-gradient(-170deg,#41546C 0%, #FFFFFF00 100%);
    box-shadow: 0 0 20px 5px #181E26;
}