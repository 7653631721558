@media only screen and (max-width: 1200px) {
    .home-page .card {
        width: 220px;
    }
}

@media only screen and (max-width: 1024px) {
    .home-page .card {
        margin: 20px auto;
        width: 500px;
    }

    #home-page-cards {
        flex-direction: column;
    }

    .home-page p {
        font-size: 20px;
    }

    .home-page .infoContainer {
        grid-template-rows: 1fr 1fr;
    }

    .home-page #cgf {
        grid-column: 1 / 3;
        grid-row: 1;
    }

    .home-page #emailForm {
        grid-column: 1 / 3;
        grid-row: 2;
    }

    .typing-container {
        height: 3rem;
    }

    .cursor {
        height: 3rem;
    }

    
}

@media only screen and (max-width: 768px) {
    #large-logo {
        filter: opacity(20%);
        right: 15%;
    }

    .home-page .card {
        margin: 20px auto;
        width: 90%;
    }
}

@media only screen and (max-width: 700px) {
    #large-logo {
        display: none;
    }
}
